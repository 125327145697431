import React from 'react';

const ChronoLogPrivacyPage = () => {
  return (
    <div className="privacy-policy">
      <h2>ChronoLog Privacy Policy</h2>

      <section>
        <h3>Data Collection and Use</h3>
        <p>
          All data generated by ChronoLog is primarily stored locally on your device. We do not collect, store, or share any personal data with third parties.
        </p>
      </section>

      <section>
        <h3>Usage Analytics, Debugging, and Product Development</h3>
        <p>
          To help us improve ChronoLog, we collect anonymous in-app usage data. This data is used for analytics, debugging, and product development purposes. No personally identifiable information is collected or stored.
        </p>
      </section>

      <section>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the updated Privacy Policy on this page.
        </p>
      </section>

      <section>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or suggestions about our Privacy Policy, please contact us at <a href="mailto:support@chronlog.com">support@chronlog.com</a>.
        </p>
      </section>

      <section>
        <p>
          By using ChronoLog, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our app. Your continued use of ChronoLog following any changes to this policy will be deemed acceptance of those changes.
        </p>
      </section>

      <section>
        <p>This policy is effective as of 2023-08-03.</p>
      </section>
    </div>
  );
};

export default ChronoLogPrivacyPage;
